<template>
    <div>
        <v-dialog v-model="$store.state.dialog3" width="400" > 
            <v-card flat class="card">

                <v-toolbar outlined flat >
                    <v-toolbar-title>الوصولات</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                
                                <v-row>
                                    <aMoneyField v-model="item.amount" :key="$store.state.itemDetails.amount"  title="المبلغ" :cols="12" :md="12" :required="true" suffix="د.ع" :fieldTitle="true" />
                                    <aTextField v-model="item.note" title="ملاحظة" :cols="12" :md="12" :required="false" :fieldTitle="true"/>
                                </v-row>
    
                            </v-container>
                        </v-card>
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
    </template>
    
    <script>
    export default {
    
        data() {
            return {
                item: {},
                search: '',
                valid: false,
            }
        },
    
        mounted() {
    this.reset();
    this.$eventBus.$on('fill-fields', () => {
        this.item = JSON.parse(JSON.stringify(this.$store.state.itemDetails));
    });
},

    
        methods: {
    
            reset() {
                this.item = {
                    "note": "",
                    "amount": "",
                }
            },
    
            submit() {
                if (this.$store.state.itemDetails.dialogType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            },
    
            addItem() {
                this.$global.state.loading = true
                this.$http.post(`Transaction`, this.item).then(res => {
                    this.$service.success('تم الإضافة بنجاح')
                    this.$eventBus.$emit(`${this.$route.path}`)
                    this.close()

                }).catch(err => {
                    this.$service.faild('حدث خطأ')
                    this.$service.faild(err.data.message)
                    this.$service.faild(JSON.stringify(err.data.result))
                }).finally(() => this.$global.state.loading = false)
            },
    
            async editItem() {
                // this.$global.state.loading = true
                // this.$http.put(`Transaction/${this.item.id}`, this.item).then(res => {
                //     this.close()
                //     this.$service.success('تم التعديل بنجاح')
                //     this.$eventBus.$emit(`${this.$route.path}`)
                // }).catch(err => {
                //     this.$service.faild('حدث خطأ')
                //     this.$service.faild(err.data.message)
                //     this.$service.faild(JSON.stringify(err.data.result))
                // }).finally(() => this.$global.state.loading = false)

                this.$global.state.loading = true
                try {
                    await this.$http.put(`Order/ChangeStatus?OrderId=${this.$store.state.itemDetails.orderId}
                                                            &NewStatusId=${this.$store.state.itemDetails.statusId}
                                                            &RecivedAmount=${this.item.amount}
                                                            &Note=${this.item.note}`)
                    // this.close()
                    this.$service.success('تم التعديل بنجاح')
                    this.$eventBus.$emit(`${this.$route.path}`)
                    this.close()

                } catch (err) {
                    console.log(err)
                    this.$service.faild('حدث خطأ')
                        this.$service.faild(JSON.stringify(err.data.message))
                } finally {
                    this.$global.state.loading = false
                }
            },
    
            close() {
                this.$store.commit('setDialog3')
                this.$store.commit('setItemDetails', {})
                this.$store.state.dialog3 = false
                this.reset()
                this.resetValidation()
                
            },
    
            resetValidation() {
                this.$refs.form.resetValidation()
            },
    
        },
    }
    </script>
    
    